import { parseAll, Theme } from "@config/theme"
import { cn } from "@lib/cn"
import React, { FC, HTMLAttributes } from "react"
import { ThemeSystemProps } from "theme-system"

type HeadingVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

export type HeadingProps = HTMLAttributes<HTMLElement> &
  Pick<ThemeSystemProps<Theme>, "mb" | "mt" | "color"> & {
    variant: HeadingVariant
    as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div" | "span"
  }

export const Heading: FC<HeadingProps> = ({
  color = "white",
  as,
  variant,
  className,
  mb,
  mt,
  ...rest
}) => {
  const Comp = as || variant
  return (
    <Comp
      // Keep data-* attributes for backward compatibility with  other usages
      // TODO: remove data-* attributes when everything is migrated to tailwind
      data-variant={variant}
      className={cn(
        "font-extrabold tracking-[-0.01rem]",
        getBaseHeadingClass(variant),
        className,
        parseAll({ color, mb, mt }),
      )}
      {...rest}
    />
  )
}

const getBaseHeadingClass = (variant: HeadingVariant) => {
  switch (variant) {
    case "h1":
      return "text-5xl lg:text-[4rem] leading-1.1"
    case "h2":
      return "text-[2.5rem] lg:text-[3.25rem] leading-1.1"
    case "h3":
      return "text-[2rem] lg:text-[2.5rem] leading-1.2"
    case "h4":
      return "text-[1.75rem] lg:text-[2rem] leading-1.2"
    case "h5":
      return "text-xxl lg:text-2xl leading-snug"
    case "h6":
      return "text-base leading-normal"
  }
}
