import { parseAll, Theme } from "@config/theme"
import { cn } from "@lib/cn"
import { FC, HTMLAttributes } from "react"
import { ThemeSystemProps } from "theme-system"

type TextVariant = "special" | "large" | "regular" | "small" | "micro"
type TextProps = HTMLAttributes<HTMLElement> &
  Pick<
    ThemeSystemProps<Theme>,
    "mb" | "mr" | "ml" | "mt" | "color" | "fontWeight"
  > & {
    variant: TextVariant
    as?: "p" | "div" | "span" | "li"
  }

// use direct arbitrary value for font-size to prevent line-height apply
const variantClass: Record<TextVariant, string> = {
  special: "font-semibold text-xxl lg:text-xl",
  large: "text-lg lg:text-base",
  regular: "text-base lg:text-sm",
  small: "text-sm",
  micro: "text-sm lg:text-xs",
}
export const Text: FC<TextProps> = ({
  color = "white",
  as = "div",
  fontWeight = "medium",
  variant,
  className,
  ...rest
}) => {
  const Comp = as
  return (
    <Comp
      // Keep data-* attributes for backward compatibility with  other usages
      // TODO: remove data-* attributes when everything is migrated to tailwind
      data-variant={variant}
      className={cn(
        variantClass[variant],
        "leading-normal",
        className,
        // For now, remove usage of this parseAll will have to make change for > 100 places as checked
        // Therefore we should keep it here and update later for each component to use classname instead
        // TODO: remove this usage of parseAll later
        parseAll({ color, fontWeight, ...rest }),
      )}
      {...rest}
    />
  )
}
